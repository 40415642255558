//When we disable the search criteria, it should also reset these search criteria value.
//This file is to keep mapping between the search criteria checkbox value and related field
//when disable 'coVslVoy', we need to clear field 'co','vsl','voy'

export const SearchCriteriaCheckboxRelatedField: { [key: string]: string[] } = {
    coVslVoy: ['co', 'vsl', 'voy'],
    etd: ['etdDateFrom', 'etdDateTo'],
    eta: ['etaDateFrom', 'etaDateTo'],
    opsDate: ['opsDateFrom', 'opsDateTo'],
    invDate: ['invDateFrom', 'invDateTo'],
    gateIn: ['gateInFrom', 'gateInTo'],
    gateOut: ['gateOutFrom', 'gateOutTo'],
}