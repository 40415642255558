
import _ from "lodash";

export const isNonDefaultValue = (searchCriteria: any, defaultCriteria: any, 
        checkBoxRelatedFields: {[key: string]: string[]}, fieldName: string) => {
    if (checkBoxRelatedFields[fieldName])
        return checkBoxRelatedFields[fieldName].find((relatedField) => isFieldNotEmptyAndNotDefault(searchCriteria[relatedField], defaultCriteria[relatedField]))
    return isFieldNotEmptyAndNotDefault(searchCriteria[fieldName], defaultCriteria[fieldName])
}


export const isFieldNotEmptyAndNotDefault = (fieldCheck: any, defaultField: any) => {
    if (fieldCheck === undefined || fieldCheck === null) {
        return false
    }
    return !_.isEqual(fieldCheck, defaultField)

}