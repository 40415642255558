import { SpecialHandlingIndMappingEntity } from "domain/entity/SpecialHandlingIndMapping/SpecialHandlingIndMappingEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { SpecialHandlingIndMappingRepository } from "./SpecialHandlingIndMappingRepo";

export const SpecialHandlingIndMappingRepoImpl = (): SpecialHandlingIndMappingRepository => {
    const url = '/v1/specialHandlingIndMapping';

    const getAllSpecialHandlingIndMappings = async() : Promise<SpecialHandlingIndMappingEntity[]> => {
        return axiosGetData(referenceAxiosInstance, `${url}`, []).then(res => {
            return _.sortBy(res.data, ["key"]);
        }).catch(err => {
            return [];
        });
    }

    const getSpecialHandlingIndMappingByKey = async(mappingType:string, specialHandlingCode: string): Promise<SpecialHandlingIndMappingEntity> => {
        return axiosGetData(referenceAxiosInstance, `${url}/${specialHandlingCode}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const createNewSpecialHandlingIndMapping = async(newData: SpecialHandlingIndMappingEntity) : Promise<SpecialHandlingIndMappingEntity> => {
        const result = axiosPostData(referenceAxiosInstance, `${url}`, newData).then(res => {
            return res.data;
        }).catch(err => {
            let errorMessage = "Update Special Handling Ind. Mapping Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"Special Handling Code ${newData.parameterDtlCode} Mapping" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const updateSpecialHandlingIndMapping = async(updatedData: SpecialHandlingIndMappingEntity): Promise<SpecialHandlingIndMappingEntity> => {
        const result = axiosPutData(referenceAxiosInstance, `${url}`, updatedData).then(res => {
            return res.data
        }).catch(error => {
            return null
        })
        return result
    }

    const deleteSpecialHandlingIndMappingByKey = async(mappingType:string, specialHandlingCode: string): Promise<boolean> => {
        return axiosDeleteData(referenceAxiosInstance, `${url}`, specialHandlingCode).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    return {
        getAllSpecialHandlingIndMappings: getAllSpecialHandlingIndMappings,
        getSpecialHandlingIndMappingByKey: getSpecialHandlingIndMappingByKey,
        createNewSpecialHandlingIndMapping: createNewSpecialHandlingIndMapping,
        updateSpecialHandlingIndMapping: updateSpecialHandlingIndMapping,
        deleteSpecialHandlingIndMappingByKey: deleteSpecialHandlingIndMappingByKey
    }
}