import { CriteriaDirectoryVoyageCheckboxOption } from "presentation/constant/ChargeDataEnquiry/CriteriaDirectoryCheckboxOption";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useChargeDataSearchVM } from "presentation/hook/ChargeDataEnquiry/useChargeDataSearchVM";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { useMemo } from "react";
import {
    HPHGroupTitle,
    HPHInputMask
} from "veronica-ui-component/dist/component/core";


export const ChargeDataSearchCriteriaEditVoyagePanel = () => {
    const CHARGE_CONSTANT = ChargeConstant.Charge;
    const [chargeDataSearchState] = useChargeDataSearchTracked();
    const chargeDataSearchCriteria = chargeDataSearchState.searchCriteria;
    const chargeDataSearchVM = useChargeDataSearchVM();
    const enabledCriteria = useMemo(() => CriteriaDirectoryVoyageCheckboxOption.find(item => chargeDataSearchState.enabledSearchCriteria[item.key]), [chargeDataSearchState.enabledSearchCriteria]);
    const memoCoVslVoy = useMemo(() =>
            <div className='im-flex-row-item'>
                <HPHInputMask
                    width="330px"
                    label={CHARGE_CONSTANT.CO_VSL_VOY}
                    value={{
                        co: chargeDataSearchCriteria.co ?? '',
                        vsl: chargeDataSearchCriteria.vsl ?? '',
                        voy: chargeDataSearchCriteria.voy ?? ''
                    }}
                    fieldConfiguration={{
                        co: { maxLength: 60, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                        vsl: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                        voy: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    }}
                    onDataChange={(e: any) => chargeDataSearchVM.onCoVslVoyChange(e, {
                        co: 'co',
                        vsl: 'vsl',
                        voy: 'voy'
                    })}/>
            </div>
        , [chargeDataSearchCriteria.co, chargeDataSearchCriteria.vsl, chargeDataSearchCriteria.voy, chargeDataSearchVM, CHARGE_CONSTANT.CO_VSL_VOY])
    const memoEtdFromTo = useMemo(() =>
            <div className='im-flex-row-item'>
                <DateRangePickerComponent
                    label={CHARGE_CONSTANT.ETD_RANGE}
                    width="330px"
                    fieldNames={{startField:"etdDateFrom", endField:"etdDateTo"}}
                    dateRange={{startDate:chargeDataSearchCriteria.etdDateFrom, endDate: chargeDataSearchCriteria.etdDateTo}}
                    onDatesChange={chargeDataSearchVM.onDateRangeChange}
                />
            </div>
        , [chargeDataSearchCriteria.etdDateFrom,chargeDataSearchCriteria.etdDateTo, chargeDataSearchVM, CHARGE_CONSTANT.ETD_RANGE])
    return (
        enabledCriteria ? <div className='im-charge-data-search-criteria-group'>
            <HPHGroupTitle titleText={CHARGE_CONSTANT.VOYAGE} />
            <CriteriaItemContainer>
                {chargeDataSearchState.enabledSearchCriteria.coVslVoy ? memoCoVslVoy : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {chargeDataSearchState.enabledSearchCriteria.etd ? memoEtdFromTo : null}
            </CriteriaItemContainer>
        </div> : null
    );
};
