import { ManualSystemChargeEntity } from "domain/entity/ChargeDataEnquiry/ManualSystemChargeEntity";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { calculateStateColor, defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithColorAndCircle } from "presentation/view/components/TableWrapper/TableCellRender";

const CHARGE_CONSTANT = ChargeConstant.Charge;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
let timeFieldList:string[] = [];

export const INITIAL_CHARGE_DATA_ENQUIRY_COL_DEF: any[] = [
    {
        headerName: CHARGE_CONSTANT.STATE,
        field: 'dtlState',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 120,
    },
    {
        headerName: CHARGE_CONSTANT.BILLING_TYPE,
        field: 'billingType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CHARGE_CONSTANT.CNTR_NO,
        field: 'cntrNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CHARGE_CONSTANT.SL_VESSEL_CODE,
        field: 'slVesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CHARGE_CONSTANT.SL_IB_VOYAGE_CODE,
        field: 'slIbVoyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CHARGE_CONSTANT.SL_OB_VOYAGE_CODE,
        field: 'slObVoyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CHARGE_CONSTANT.PREVIEW_REF_NO,
        field: 'defaultPreviewRefNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CHARGE_CONSTANT.OPS_DATE,
        field: 'opsDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType : "date",
        width: 150,        
    },
    {
        headerName: CHARGE_CONSTANT.CHARGE_TYPE,
        field: 'chargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CHARGE_CONSTANT.SUB_CHARGE_TYPE,
        field: 'subChargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CHARGE_CONSTANT.ALTERABLE_CHARGE_ON_COMPANY,
        field: 'alterableChargeOnCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.ALTERABLE_BILL_TO_COMPANY,
        field: 'alterableBillToCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.CHARGE_ON_COMPANY,
        field: 'chargeOnCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CHARGE_CONSTANT.BILL_TO_COMPANY,
        field: 'billToCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CHARGE_CONSTANT.CONSORTIUM_CODE,
        field: 'consortiumCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.VESSEL_CODE,
        field: 'vesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.VOYAGE_CODE,
        field: 'voyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.HANDLING_TML,
        field: 'handlingTerminal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.CONSORTIUM_CODE_ARRIVAL,
        field: 'arrivalConsortiumCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.VESSEL_NAME_ARRIVAL,
        field: 'arrivalVesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.VOYAGE_CODE_ARRIVAL,
        field: 'arrivalVoyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.SERVICE_CODE_ARRIVAL,
        field: 'arrivalServiceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.VESSEL_NAME_ARRIVAL,
        field: 'arrivalVesselName',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.CONSORTIUM_CODE_DEPATURE,
        field: 'departureConsortiumCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.VESSEL_CODE_DEPATURE,
        field: 'departureVesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.VOYAGE_CODE_DEPATURE,
        field: 'departureVoyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.SERVICE_CODE_DEPATURE,
        field: 'departureServiceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.VESSEL_NAME_DEPATURE,
        field: 'departureVesselName',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.ETD,
        field: 'etd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        dataType : "dateTime",
        filter: false,
        width: 150,
    },
    {
        headerName: CHARGE_CONSTANT.BUNDLE_IND,
        field: 'bundleInd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.OPERATION_TYPE,
        field: 'operationType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.INVOICE_NO,
        field: 'defaultInvoiceNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.INVOICE_DATE,
        field: 'docDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType : "dateTime",
        width: 200,
    },
    {
        headerName: CHARGE_CONSTANT.CREDIT_NOTE_NO,
        field: 'defaultCreditNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.CREDIT_NOTE_DATE,
        field: 'creditNoteDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType : "dateTime",
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.DRAFT_INVOICE_NO,
        field: 'defaultDraftInvoiceNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.DRAFT_CREDITE_NOTE_NO,
        field: 'defaultDraftCreditNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.ADJ_TYPE,
        field: 'adjType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CHARGE_CONSTANT.BILLING_CYCLE_CODE,
        field: 'billingCycleCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.SIZE,
        field: 'cntrSize',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 90,
    },
    {
        headerName: CHARGE_CONSTANT.TYPE,
        field: 'cntrType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 90,
    },
    {
        headerName: CHARGE_CONSTANT.TARIFF_CODE,
        field: 'tariffCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.TARIFF_CODE_DESC,
        field: 'tariffCodeDesc',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.END_VISIT_IND,
        field: 'endVisitIndicator',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.MODALITY_OF_ARRIVAL,
        field: 'modalityOfArrival',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.MODALITY_OF_DEPATURE,
        field: 'modalityOfDeparture',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.FULL_EMPTY,
        field: 'cntrStatus',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.CONTAINER_VISIT,
        field: 'cntrVisitId',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.EXCEPTION_MESSAGE,
        field: 'exceptionMsg',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.OPS_DATE_TIME,
        field: 'etd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        dataType : "dateTime",
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.GATE_IN,
        field: 'gateInDatetime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        dataType : "dateTime",
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.GATE_OUT,
        field: 'gateOutDatetime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        dataType : "dateTime",
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.IN_YARD_DATE,
        field: 'inYardDay',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.CHARGING_QTY_OT_DAYS,
        field: 'chargingQtyOtfays',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: CHARGE_CONSTANT.SPECIAL_HANDLING_CODE,
        field: 'specialHandlingCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.SPECIAL_HANDLING_CODE_D1,
        field: 'legSpHandlingCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.MANUAL_SYSTEM_IND,
        field: 'manualSysInd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.FROM_TML,
        field: 'fromTml',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_CONSTANT.TO_TML,
        field: 'toTml',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },    
].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};
    cellRenderers['dtlState'] = cellRenderWithColorAndCircle;

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, timeFieldList, cellRenderers);
});

export const transferRowData = (data:ManualSystemChargeEntity[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};
    externalFnctions['dtlState'] = calculateStateColor;

    return transferRowDataInternal<ManualSystemChargeEntity>(data, dateFieldList, dateTimeFieldList, timeFieldList, externalFnctions);
}