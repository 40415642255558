import _ from "lodash";
import { DropdownProps } from "presentation/model/DropdownProps";
import { ChargeConstant } from "../ChargeHeader/ChargeConstant";

export const CriteriaDirectoryDropdownOption: DropdownProps[] = _.orderBy([
    { dropdownLabel: ChargeConstant.Charge.CO_VSL_VOY, value: 'coVslVoy', tagLabel: ChargeConstant.Charge.CO_VSL_VOY },
    { dropdownLabel: ChargeConstant.Charge.ETD_RANGE, value: 'etd', tagLabel: ChargeConstant.Charge.ETD_RANGE },
    { dropdownLabel: ChargeConstant.Charge.OPS_DATE_RANGE, value: 'opsDate', tagLabel: ChargeConstant.Charge.OPS_DATE_RANGE },
    { dropdownLabel: ChargeConstant.Charge.CHARGE_ON_COMPANY, value: 'chgOnCompList', tagLabel: ChargeConstant.Charge.CHARGE_ON_COMPANY },
    { dropdownLabel: ChargeConstant.Charge.BILL_TO_COMPANY, value: 'billToCompList', tagLabel: ChargeConstant.Charge.BILL_TO_COMPANY },
    { dropdownLabel: ChargeConstant.Charge.CHARGE_TYPE, value: 'chgTypeList', tagLabel: ChargeConstant.Charge.CHARGE_TYPE },
    { dropdownLabel: ChargeConstant.Charge.SUB_CHARGE_TYPE, value: 'subChgTypeList', tagLabel: ChargeConstant.Charge.SUB_CHARGE_TYPE },
    { dropdownLabel: ChargeConstant.Charge.STATE, value: 'stateList', tagLabel: ChargeConstant.Charge.STATE },
    { dropdownLabel: ChargeConstant.Charge.TARIFF_CODE, value: 'tarCodeList', tagLabel: ChargeConstant.Charge.TARIFF_CODE },
    { dropdownLabel: ChargeConstant.Charge.TARIFF_CODE_DESC, value: 'tarCodeDesc', tagLabel: ChargeConstant.Charge.TARIFF_CODE_DESC },
    { dropdownLabel: ChargeConstant.Charge.EXCEPTION_MESSAGE, value: 'expMsg', tagLabel: ChargeConstant.Charge.EXCEPTION_MESSAGE },
    { dropdownLabel: ChargeConstant.Charge.BILLING_TYPE, value: 'billingTypeList', tagLabel: ChargeConstant.Charge.BILLING_TYPE },
    { dropdownLabel: ChargeConstant.Charge.BILLING_CYCLE_CODE, value: 'billingCycleCode', tagLabel: ChargeConstant.Charge.BILLING_CYCLE_CODE },
    { dropdownLabel: ChargeConstant.Charge.MANUAL_SYSTEM_IND, value: 'manSysInd', tagLabel: ChargeConstant.Charge.MANUAL_SYSTEM_IND },
    { dropdownLabel: ChargeConstant.Charge.INVOICE_DATE_RANGE, value: 'invDate', tagLabel: ChargeConstant.Charge.INVOICE_DATE_RANGE },
    { dropdownLabel: ChargeConstant.Charge.INV_CRE_NOTE_NO, value: 'invNo', tagLabel: ChargeConstant.Charge.INV_CRE_NOTE_NO },
    { dropdownLabel: ChargeConstant.Charge.PREVIEW_REF_NO, value: 'previewRefNo', tagLabel: ChargeConstant.Charge.PREVIEW_REF_NO },
    { dropdownLabel: ChargeConstant.Charge.CNTR_NO, value: 'cntrList', tagLabel: ChargeConstant.Charge.CNTR_NO },
    { dropdownLabel: ChargeConstant.Charge.HANDLING_TML, value: 'handlingTmlList', tagLabel: ChargeConstant.Charge.HANDLING_TML },
    { dropdownLabel: ChargeConstant.Charge.OPERATION_TYPE, value: 'operationTypeList', tagLabel: ChargeConstant.Charge.OPERATION_TYPE },
    { dropdownLabel: ChargeConstant.Charge.BUNDLE_IND, value: 'bundleIndList', tagLabel: ChargeConstant.Charge.BUNDLE_IND },
    { dropdownLabel: ChargeConstant.Charge.CONTAINER_VISIT, value: 'cntrVisit', tagLabel: ChargeConstant.Charge.CONTAINER_VISIT },
    { dropdownLabel: ChargeConstant.Charge.SIZE, value: 'sizeList', tagLabel: ChargeConstant.Charge.SIZE },
    { dropdownLabel: ChargeConstant.Charge.TYPE, value: 'typeList', tagLabel: ChargeConstant.Charge.TYPE },
    { dropdownLabel: ChargeConstant.Charge.MODALITY_OF_ARRIVAL, value: 'modalityOfArrivalList', tagLabel: ChargeConstant.Charge.MODALITY_OF_ARRIVAL },
    { dropdownLabel: ChargeConstant.Charge.MODALITY_OF_DEPATURE, value: 'modalityofDepatureList', tagLabel: ChargeConstant.Charge.MODALITY_OF_DEPATURE },
    { dropdownLabel: ChargeConstant.Charge.FULL_EMPTY, value: 'fullEmptyInd', tagLabel: ChargeConstant.Charge.FULL_EMPTY },
    { dropdownLabel: ChargeConstant.Charge.GATE_IN_RANGE, value: 'gateIn', tagLabel: ChargeConstant.Charge.GATE_IN_RANGE },
    { dropdownLabel: ChargeConstant.Charge.GATE_OUT_RANGE, value: 'gateOut', tagLabel: ChargeConstant.Charge.GATE_OUT_RANGE },
    { dropdownLabel: ChargeConstant.Charge.SPECIAL_HANDLING_CODE, value: 'specHandlingCodeList', tagLabel: ChargeConstant.Charge.SPECIAL_HANDLING_CODE },
    { dropdownLabel: ChargeConstant.Charge.SPECIAL_HANDLING_CODE_D1, value: 'specHandlingCodeD1List', tagLabel: ChargeConstant.Charge.SPECIAL_HANDLING_CODE_D1 },
    { dropdownLabel: ChargeConstant.Charge.END_VISIT_IND, value: 'endVisitInd', tagLabel: ChargeConstant.Charge.END_VISIT_IND },
    { dropdownLabel: ChargeConstant.Charge.FROM_TML, value: 'fromTml', tagLabel: ChargeConstant.Charge.FROM_TML },
    { dropdownLabel: ChargeConstant.Charge.TO_TML, value: 'toTml', tagLabel: ChargeConstant.Charge.TO_TML },
], 'dropdownLabel')
