export interface EnabledSearchCriteria {
    all: boolean,
    voyage: boolean,
    charge:boolean,
    container: boolean,
    document: boolean,
    coVslVoy: boolean,
    opsDate: boolean,
    invDate: boolean,
    etd: boolean,
    chgOnCompList: boolean,
    billToCompList: boolean,
    cntrList: boolean,
    chgTypeList: boolean,
    subChgTypeList: boolean,
    stateList: boolean,
    invNo: boolean,
    handlingTmlList: boolean,
    previewRefNo: boolean,
    operationTypeList: boolean,
    bundleIndList: boolean,
    cntrVisit: boolean,
    sizeList: boolean,
    modalityOfArrivalList: boolean,
    modalityofDepatureList: boolean,
    fullEmptyInd: boolean,
    typeList: boolean,
    gateIn: boolean,
    specHandlingCodeList: boolean,
    gateOut: boolean,
    specHandlingCodeD1List: boolean,
    tarCodeList: boolean,
    tarCodeDesc: boolean,
    fromTml: boolean,
    toTml: boolean,
    endVisitInd: boolean,
    expMsg: boolean,
    billingTypeList: boolean,
    billingCycleCode: boolean,
    manSysInd: boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_CHARGE_DATA_SEARCH_CRITERIA: EnabledSearchCriteria = {
    all: false,
    voyage: true,
    charge:true,
    container: true,
    document: false,
    coVslVoy: true,
    opsDate: true,
    invDate: false,
    etd: false,
    chgOnCompList: true,
    billToCompList: true,
    cntrList: true,
    chgTypeList: true,
    subChgTypeList: true,
    stateList: true,
    invNo: true,
    handlingTmlList: false,
    previewRefNo: true,
    operationTypeList: false,
    bundleIndList: false,
    cntrVisit: false,
    sizeList: false,
    modalityOfArrivalList: false,
    modalityofDepatureList: false,
    fullEmptyInd: false,
    typeList: false,
    gateIn: false,
    specHandlingCodeList: false,
    gateOut: false,
    specHandlingCodeD1List: false,
    tarCodeList: false,
    tarCodeDesc: false,
    fromTml: false,
    toTml: false,
    endVisitInd: false,
    expMsg: false,
    billingTypeList: true,
    billingCycleCode: true,
    manSysInd: false,
}