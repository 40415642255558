import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { ChargeDataSearchCriteriaDirectoryPanel } from "../ChargeDataSearchCriteriaDirectoryPanel/ChargeDataSearchCriteriaDirectoryPanel";
import { ChargeDataSearchCriteriaPanel } from "./ChargeDataSearchCriteriaPanel/ChargeDataSearchCriteriaPanel";


export const ChargeDataSearchRightPanel = () => {
    const [chargeDataSearchState] = useChargeDataSearchTracked();
    const isFilterSearchCriteria = chargeDataSearchState.isFilterSearchCriteria;
    const isShowRightCriteriaPanel = chargeDataSearchState.isShowRightCriteriaPanel;

    return <div className='im-charge-data-search-right-panel'>
        {isShowRightCriteriaPanel && isFilterSearchCriteria && <><div className='im-charge-data-search-right-panel-title'>SEARCH CRITERIA</div><ChargeDataSearchCriteriaPanel /></>}
        {isShowRightCriteriaPanel && !isFilterSearchCriteria && <><div className='im-charge-data-search-right-panel-title'>CRITERIA DIRECTORY</div><ChargeDataSearchCriteriaDirectoryPanel /></>}
    </div>;
};
