import { EMPTY_CHARGE_DATA_SEARCH_CRITERIA } from "presentation/constant/ChargeDataEnquiry/ChargeDataSearchCriteria";
import { CriteriaDirectoryContainerCheckboxOption } from "presentation/constant/ChargeDataEnquiry/CriteriaDirectoryCheckboxOption";
import { SearchCriteriaCheckboxRelatedField } from "presentation/constant/ChargeDataEnquiry/SearchCriteriaCheckboxRelatedField";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useChargeDataSearchVM } from "presentation/hook/ChargeDataEnquiry/useChargeDataSearchVM";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { isNonDefaultValue } from "presentation/utils/searchCriteriaUtil";
import { convertDateToDateForComponent } from "presentation/utils/timeUtil";
import { useCallback, useMemo } from "react";
import { HPHGroupTitle, Tags } from "veronica-ui-component/dist/component/core";

export const ChargeDataSearchCriteriaPreviewContainerPanel = () => {
    const [chargeDataSearchState] = useChargeDataSearchTracked();
    const searchCriteria = chargeDataSearchState.searchCriteria;
    const chargeDataSearchVM = useChargeDataSearchVM();
    const enabledCriteria = useMemo(() => CriteriaDirectoryContainerCheckboxOption.find(item => chargeDataSearchState.enabledSearchCriteria[item.key]), [chargeDataSearchState.enabledSearchCriteria])
    const isAllDefaultValue = useMemo(() => {
        const attributeField = CriteriaDirectoryContainerCheckboxOption.filter(item => chargeDataSearchState.enabledSearchCriteria[item.key]).map(item => item.key)
        const isAllDefault = !attributeField.find((field) => {
            return isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,field)
        })
        return isAllDefault
    }, [searchCriteria, chargeDataSearchState.enabledSearchCriteria]);    
    const chargeConstant = ChargeConstant;

    const handleRemoveSearchCriteira = useCallback((fieldName: string, isSearch?: boolean) => {
        chargeDataSearchVM.showLoading();
        chargeDataSearchVM.onRemoveSearchCriteria(fieldName, isSearch);
        chargeDataSearchVM.hideLoading();
    }, [chargeDataSearchVM]);

    const gateInFromStr = convertDateToDateForComponent(searchCriteria.gateInFrom);
    const gateInToStr = convertDateToDateForComponent(searchCriteria.gateInTo);
    const gateOutFromStr = convertDateToDateForComponent(searchCriteria.gateOutFrom);
    const gateOutToStr = convertDateToDateForComponent(searchCriteria.gateOutTo);

    return (
        (enabledCriteria && !isAllDefaultValue) ? <div className='im-charge-data-search-criteria-group'>
            <HPHGroupTitle id='im-preview-tag-group-title' titleText='Container'/>
            <div className='im-charge-data-search-criteria-preview-tag-group'>
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,'cntrList') && <Tags
                    label={`${chargeConstant.Charge.CNTR_NO} : ${searchCriteria.cntrList}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('cntrList')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,'handlingTmlList') && <Tags
                    label={`${chargeConstant.Charge.HANDLING_TML} : ${searchCriteria.handlingTmlList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('handlingTmlList')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,'operationTypeList') && <Tags
                    label={`${chargeConstant.Charge.OPERATION_TYPE} : ${searchCriteria.operationTypeList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('operationTypeList')}
                />}
                {isNonDefaultValue(searchCriteria,EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField, 'bundleIndList') && <Tags
                    label={`${chargeConstant.Charge.BUNDLE_IND} : ${searchCriteria.bundleIndList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('bundleIndList')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,'cntrVisit') && <Tags
                    label={`${chargeConstant.Charge.CONTAINER_VISIT} : ${searchCriteria.cntrVisit}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('cntrVisit')}
                />}
                {isNonDefaultValue(searchCriteria,EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField, 'sizeList') && <Tags
                    label={`${chargeConstant.Charge.SIZE} : ${searchCriteria.sizeList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('sizeList')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,'typeList') && <Tags
                    label={`${chargeConstant.Charge.TYPE} : ${searchCriteria.typeList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('typeList')}
                />}
                {isNonDefaultValue(searchCriteria,EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField, 'modalityOfArrivalList') && <Tags
                    label={`${chargeConstant.Charge.MODALITY_OF_ARRIVAL} : ${searchCriteria.modalityOfArrivalList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('modalityOfArrivalList')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,'modalityofDepatureList') && <Tags
                    label={`${chargeConstant.Charge.MODALITY_OF_DEPATURE} : ${searchCriteria.modalityofDepatureList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('modalityofDepatureList')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,'fullEmptyInd') && <Tags
                    label={`${chargeConstant.Charge.FULL_EMPTY} : ${searchCriteria.fullEmptyInd}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('fullEmptyInd')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,'endVisitInd') && <Tags
                    label={`${chargeConstant.Charge.END_VISIT_IND} : ${searchCriteria.endVisitInd}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('endVisitInd')}
                />}
                {isNonDefaultValue(searchCriteria,EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField, 'gateIn') && <Tags
                    label={`${chargeConstant.Charge.GATE_IN_RANGE} : ${gateInFromStr} - ${gateInToStr}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('gateIn')}
                />}    
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,'gateOut') && <Tags
                    label={`${chargeConstant.Charge.GATE_OUT_RANGE} : ${gateOutFromStr} - ${gateOutToStr}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('gateOut')}
                />}    
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,'specHandlingCodeList') && <Tags
                    label={`${chargeConstant.Charge.SPECIAL_HANDLING_CODE} : ${searchCriteria.specHandlingCodeList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('specHandlingCodeList')}
                />}       
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,'specHandlingCodeD1List') && <Tags
                    label={`${chargeConstant.Charge.SPECIAL_HANDLING_CODE_D1} : ${searchCriteria.specHandlingCodeD1List?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('specHandlingCodeD1List')}
                />}     
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,'fromTml') && <Tags
                    label={`${chargeConstant.Charge.FROM_TML} : ${searchCriteria.fromTml?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('fromTml')}
                />}  
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,'toTml') && <Tags
                    label={`${chargeConstant.Charge.TO_TML} : ${searchCriteria.toTml?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('toTml')}
                />}   
            </div>
        </div> : null
    );
};
