import { EMPTY_CHARGE_DATA_SEARCH_CRITERIA } from "presentation/constant/ChargeDataEnquiry/ChargeDataSearchCriteria";
import { CriteriaDirectoryVoyageCheckboxOption } from "presentation/constant/ChargeDataEnquiry/CriteriaDirectoryCheckboxOption";
import { SearchCriteriaCheckboxRelatedField } from "presentation/constant/ChargeDataEnquiry/SearchCriteriaCheckboxRelatedField";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useChargeDataSearchVM } from "presentation/hook/ChargeDataEnquiry/useChargeDataSearchVM";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { isNonDefaultValue } from "presentation/utils/searchCriteriaUtil";
import { convertDateToDateForComponent } from "presentation/utils/timeUtil";
import { useCallback, useMemo } from "react";
import { HPHGroupTitle, Tags } from "veronica-ui-component/dist/component/core";

export const ChargeDataSearchCriteriaPreviewVoyagePanel = () => {
    const [chargeDataSearchState] = useChargeDataSearchTracked();
    const searchCriteria = chargeDataSearchState.searchCriteria;
    const chargeDataSearchVM = useChargeDataSearchVM();
    const enabledCriteria = useMemo(() => CriteriaDirectoryVoyageCheckboxOption.find(item => chargeDataSearchState.enabledSearchCriteria[item.key]), [chargeDataSearchState.enabledSearchCriteria])
    const isAllDefaultValue = useMemo(() => {
        const attributeField = CriteriaDirectoryVoyageCheckboxOption.filter(item => chargeDataSearchState.enabledSearchCriteria[item.key]).map(item => item.key)
        const isAllDefault = !attributeField.find((field) => {
            return isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,field)
        })
        return isAllDefault
    }, [searchCriteria, chargeDataSearchState.enabledSearchCriteria])  

    const handleRemoveSearchCriteira = useCallback((fieldName: string, isSearch?: boolean) => {
        chargeDataSearchVM.showLoading();
        chargeDataSearchVM.onRemoveSearchCriteria(fieldName, isSearch);
        chargeDataSearchVM.hideLoading();
    }, [chargeDataSearchVM]);
    const chargeConstant = ChargeConstant;

    const etdFromStr = convertDateToDateForComponent(searchCriteria.etdDateFrom);
    const etdToStr = convertDateToDateForComponent(searchCriteria.etdDateTo);

    return (
        (enabledCriteria && !isAllDefaultValue) ? <div className='im-charge-data-search-criteria-group'>
            <HPHGroupTitle id='im-preview-tag-group-title' titleText='Voyage'/>
            <div className='im-charge-data-search-criteria-preview-tag-group'>
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,'coVslVoy') && <Tags
                    label={`${chargeConstant.Charge.CO_VSL_VOY} : ${searchCriteria.co}/${searchCriteria.vsl}/${searchCriteria.voy}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('coVslVoy')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA,SearchCriteriaCheckboxRelatedField,'etd') && <Tags
                    label={`${chargeConstant.Charge.ETD_RANGE} : ${etdFromStr} - ${etdToStr}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('etd')}
                />}
            </div>
        </div> : null
    );
};
