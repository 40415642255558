import { ChargeDataSearchRequestEntity } from "domain/entity/ChargeDataEnquiry/ChargeDataSearchRequestEntity";
import { ManualSystemChargeEntity } from "domain/entity/ChargeDataEnquiry/ManualSystemChargeEntity";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { ChargeDataEnquiryRepository } from "./ChargeDataEnquiryRepo";

export const ChargeDataEnquiryRepoImpl = () : ChargeDataEnquiryRepository => {
    const url = "/v1/chargeDataEnquirySearch"

    const getManualSystemCharges = (request: ChargeDataSearchRequestEntity) : Promise<ManualSystemChargeEntity[]> => {
        return axiosPostData(chargeAxiosInstance, `${url}`, request).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    return {
        getManualSystemCharges: getManualSystemCharges
    }
}