import { useMemo } from "react";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { SpecialHandlingIndMappingRepoImpl } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepoImpl";
import { ChargeDataEnquiryRepoImpl } from "domain/repository/ChargeDataEnquiry/ChargeDataEnquiryRepoImpl";
import { ChargeDataSearchVM } from "presentation/viewModel/ChargeDataEnquiry/ChargeDataEnquiryVM/ChargeDataSearchVM";
import { SearchPreferenceRepoImpl } from "domain/repository/Common/SearchPreferenceRepoImpl";

export const useChargeDataSearchVM = () => {
    const [, setChargeDataSearchState] = useChargeDataSearchTracked();
    const chargeDataSearchVM = useMemo(() =>
        ChargeDataSearchVM({
            dispatch: [setChargeDataSearchState],
            companyRepo: CompanyRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepoImpl(),
            chargeDataEnquiryRepo: ChargeDataEnquiryRepoImpl(),
            searchPreferenceRepo: SearchPreferenceRepoImpl(),
        }), [setChargeDataSearchState])

    return chargeDataSearchVM
}