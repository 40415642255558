import { ManualSystemChargeEntity } from "domain/entity/ChargeDataEnquiry/ManualSystemChargeEntity";
import { SearchPreferenceEntity } from "domain/entity/Common/SearchPreferenceEntity";
import { ChargeDataSearchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA } from "presentation/constant/ChargeDataEnquiry/ChargeDataSearchCriteria";
import { DEFAULT_ENABLED_CHARGE_DATA_SEARCH_CRITERIA, EnabledSearchCriteria } from "presentation/constant/ChargeDataEnquiry/EnabledSearchCriteria";
import { RefObject } from "react";
import { DropdownProps } from "../DropdownProps";

export interface ChargeDataSearchDropdownOptions {
    chgOnCompDropdownOptions: DropdownProps[],
    billToCompDropdownOptions: DropdownProps[],
    chgTypeDropdownOptions: DropdownProps[],
    subChgTypeDropdownOptions: { [key: string]: DropdownProps[] },
    stateDropdownOptions: DropdownProps[],
    handlingTmlDropdownOptions: DropdownProps[],
    operationTypeDropdownOptions: DropdownProps[],
    bundleIndDropdownOptions: DropdownProps[],
    sizeGroupDropdownOptions: DropdownProps[],
    typeDropdownOptions: DropdownProps[],
    fullEmptyIndDropdownOptions: DropdownProps[],
    modalityArrivalDropdownOptions: DropdownProps[] ,
    modalityDepatureDropdownOptions: DropdownProps[],
    specHandingCodeDropdownOptions: DropdownProps[],
    specHandingCodeD1DropdownOptions: DropdownProps[],
    tarCodeDropdownOptions: DropdownProps[],
    fromTmlDropdownOptions: DropdownProps[],
    toTmlDropdownOptions: DropdownProps[],
    endVisitIndDropdownOptions: DropdownProps[],
    billingTypeDropdownOptions: DropdownProps[],
    manSysDropdownOptions: DropdownProps[],
}

export interface ChargeDataSearchModel {
    isLoading:boolean,
    isShowRightCriteriaPanel: boolean,
    isFilterSearchCriteria: boolean,
    isEditSearchCriteria: boolean,
    checkboxRef: { [key: string]: HTMLElement | null },
    enabledSearchCriteria: EnabledSearchCriteria,
    checkboxValue: EnabledSearchCriteria,
    searchCriteria: ChargeDataSearchCriteria,
    highlightedCheckboxKey?: string | null
    currentTableRef?: RefObject<any>,
    searchCounter: number,
    dynamicOptions: ChargeDataSearchDropdownOptions,
    documentActive: boolean,
    isShowSaveNewConfirmModal: boolean,
    isShowDeleteConfirmModal: boolean,
    userEmail: string,
    searchPreferences: SearchPreferenceEntity<ChargeDataSearchCriteria,EnabledSearchCriteria>[],
    currentSearchPreference: SearchPreferenceEntity<ChargeDataSearchCriteria,EnabledSearchCriteria> | null,
    openedContainerNo?: string,
    isShowContainerDetail: boolean;
    sysManuCharges: ManualSystemChargeEntity[],
    isShowLegendButton: boolean,
}

export const EMPTY_CHARGE_DATA_SEARCH_MODEL: ChargeDataSearchModel = {
    isLoading: true,
    isShowRightCriteriaPanel: true,
    isFilterSearchCriteria: true,
    isEditSearchCriteria: true,
    checkboxRef: {},
    enabledSearchCriteria: { ...DEFAULT_ENABLED_CHARGE_DATA_SEARCH_CRITERIA },
    checkboxValue: { ...DEFAULT_ENABLED_CHARGE_DATA_SEARCH_CRITERIA },
    searchCriteria: { ...EMPTY_CHARGE_DATA_SEARCH_CRITERIA },
    searchCounter: 0,
    dynamicOptions: {
        chgOnCompDropdownOptions: [],
        billToCompDropdownOptions: [],
        chgTypeDropdownOptions: [],
        subChgTypeDropdownOptions: { },
        stateDropdownOptions: [],
        handlingTmlDropdownOptions: [],
        operationTypeDropdownOptions: [],
        bundleIndDropdownOptions: [],
        sizeGroupDropdownOptions: [],
        typeDropdownOptions: [],
        fullEmptyIndDropdownOptions: [],
        modalityArrivalDropdownOptions: [],
        modalityDepatureDropdownOptions: [],
        specHandingCodeDropdownOptions: [],
        specHandingCodeD1DropdownOptions: [],
        tarCodeDropdownOptions: [],
        fromTmlDropdownOptions: [],
        toTmlDropdownOptions: [],
        endVisitIndDropdownOptions: [],
        billingTypeDropdownOptions: [],
        manSysDropdownOptions: [],
    },
    documentActive: false,
    isShowSaveNewConfirmModal: false,
    isShowDeleteConfirmModal: false,
    userEmail: "",
    searchPreferences: [],
    currentSearchPreference: null,
    isShowContainerDetail: false,
    sysManuCharges: [],
    isShowLegendButton: false,
}