export interface ChargeDataSearchCriteria {    
    co?: string | null,
    vsl?: string | null,
    voy?: string | null,
    opsDateFrom?: Date | null,
    opsDateTo?: Date | null,
    invDateFrom?: Date | null,
    invDateTo?: Date | null,
    etdDateFrom?: Date | null,
    etdDateTo?: Date | null,
    chgOnCompList?: string[] | null,
    billToCompList?: string[] | null,
    cntrList?: string[] | null,
    chgTypeList?: string[] | null,
    subChgTypeList? : string [] | null,
    stateList?: string[] | null,
    invNo?: string | null,
    handlingTmlList?: string[] | null,
    previewRefNo?: number | null,
    operationTypeList?: string[] | null,
    bundleIndList?: string[] | null,
    cntrVisit?: string | null,
    sizeList?: string[] | null,
    modalityOfArrivalList?: string[] | null,
    modalityofDepatureList?: string[] | null,
    fullEmptyInd?: string | null,
    typeList?: string[] | null,
    gateInFrom?: Date | null,
    gateInTo?: Date | null,
    specHandlingCodeList?: string[] | null,
    gateOutFrom?: Date | null,
    gateOutTo?: Date | null,
    specHandlingCodeD1List?: string[] | null,
    tarCodeList?: string[] | null,
    tarCodeDesc?: string | null,
    fromTml?: string[] | null,
    toTml?: string[] | null,
    endVisitInd?: string | null,
    expMsg?: string | null,
    billingTypeList?: string[] | null,
    billingCycleCode?: string | null,
    manSysInd?: string[] | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_CHARGE_DATA_SEARCH_CRITERIA : ChargeDataSearchCriteria = {
    co: "",
    vsl: "",
    voy: "",
    opsDateFrom: null,
    opsDateTo: null,
    invDateFrom: null,
    invDateTo: null,
    etdDateFrom: null,
    etdDateTo: null,
    chgOnCompList: [],
    billToCompList: [],
    cntrList: [],
    chgTypeList: [],
    subChgTypeList: [],
    stateList: [],
    invNo: "",
    handlingTmlList: [],
    previewRefNo: null,
    operationTypeList: [],
    bundleIndList: [],
    cntrVisit: "",
    sizeList: [],
    modalityOfArrivalList: [],
    modalityofDepatureList: [],
    fullEmptyInd: "",
    typeList: [],
    gateInFrom: null,
    gateInTo: null,
    specHandlingCodeList: [],
    gateOutFrom: null,
    gateOutTo: null,
    specHandlingCodeD1List: [],
    tarCodeList: [],
    tarCodeDesc: "",
    fromTml: [],
    toTml: [],
    endVisitInd: "",
    expMsg: "",
    billingTypeList: [],
    billingCycleCode: "",
    manSysInd: [],
}