import { CheckboxProps } from "presentation/model/CheckBoxprops"
import { ChargeConstant } from "../ChargeHeader/ChargeConstant"

interface CheckboxPropsList {
    option: CheckboxProps,
    checkboxList?: CheckboxProps[]
}

export const CriteriaDirectoryAllCheckboxOption: CheckboxProps = {key: 'all', name: 'All'}

export const CriteriaDirectoryVoyageCheckboxOption: CheckboxProps[] = [
    {key: 'voyage', name: ChargeConstant.Charge.VOYAGE},
    {key: 'coVslVoy', name: ChargeConstant.Charge.CO_VSL_VOY},
    {key: 'etd', name: ChargeConstant.Charge.ETD_RANGE},
]

export const CriteriaDirectoryVoyageCheckboxOptionList: CheckboxPropsList = {
    option: {key: 'voyage', name: ChargeConstant.Charge.VOYAGE},
    checkboxList: CriteriaDirectoryVoyageCheckboxOption,
}

export const CriteriaDirectoryChargeCheckboxOption: CheckboxProps[] = [
    {key: 'charge', name: ChargeConstant.Charge.CHARGE},
    {key: 'opsDate', name: ChargeConstant.Charge.OPS_DATE_RANGE},
    {key: 'chgOnCompList', name: ChargeConstant.Charge.CHARGE_ON_COMPANY},
    {key: 'billToCompList', name: ChargeConstant.Charge.BILL_TO_COMPANY},
    {key: 'chgTypeList', name: ChargeConstant.Charge.CHARGE_TYPE},
    {key: 'subChgTypeList', name: ChargeConstant.Charge.SUB_CHARGE_TYPE},
    {key: 'stateList', name: ChargeConstant.Charge.STATE},
    {key: 'tarCodeList', name: ChargeConstant.Charge.TARIFF_CODE},
    {key: 'tarCodeDesc', name: ChargeConstant.Charge.TARIFF_CODE_DESC},
    {key: 'expMsg', name: ChargeConstant.Charge.EXCEPTION_MESSAGE},
    {key: 'billingTypeList', name: ChargeConstant.Charge.BILLING_TYPE},
    {key: 'billingCycleCode', name: ChargeConstant.Charge.BILLING_CYCLE_CODE},
    {key: 'manSysInd', name: ChargeConstant.Charge.MANUAL_SYSTEM_IND},    
]

export const CriteriaDirectoryChargeCheckboxOptionList: CheckboxPropsList = {
    option: {key: 'charge', name: ChargeConstant.Charge.CHARGE},
    checkboxList: CriteriaDirectoryChargeCheckboxOption,
}

export const CriteriaDirectoryDocumentCheckboxOption: CheckboxProps[] = [
    {key: 'document', name: ChargeConstant.Charge.DOCUMENT},
    {key: 'invDate', name: ChargeConstant.Charge.INVOICE_DATE_RANGE},
    {key: 'invNo', name: ChargeConstant.Charge.INV_CRE_NOTE_NO},
    {key: 'previewRefNo', name: ChargeConstant.Charge.PREVIEW_REF_NO},
]

export const CriteriaDirectoryDocumentCheckboxOptionList: CheckboxPropsList = {
    option: {key: 'document', name: ChargeConstant.Charge.DOCUMENT},
    checkboxList: CriteriaDirectoryDocumentCheckboxOption,
}

export const CriteriaDirectoryContainerCheckboxOption: CheckboxProps[] = [
    {key: 'container', name: ChargeConstant.Charge.CONTAINER},
    {key: 'cntrList', name: ChargeConstant.Charge.CNTR_NO},
    {key: 'handlingTmlList', name: ChargeConstant.Charge.HANDLING_TML},
    {key: 'operationTypeList', name: ChargeConstant.Charge.OPERATION_TYPE},
    {key: 'bundleIndList', name: ChargeConstant.Charge.BUNDLE_IND},
    {key: 'cntrVisit', name: ChargeConstant.Charge.CONTAINER_VISIT},
    {key: 'sizeList', name: ChargeConstant.Charge.SIZE},
    {key: 'typeList', name: ChargeConstant.Charge.TYPE},
    {key: 'modalityOfArrivalList', name: ChargeConstant.Charge.MODALITY_OF_ARRIVAL},
    {key: 'modalityofDepatureList', name: ChargeConstant.Charge.MODALITY_OF_DEPATURE},
    {key: 'fullEmptyInd', name: ChargeConstant.Charge.FULL_EMPTY},
    {key: 'gateIn', name: ChargeConstant.Charge.GATE_IN_RANGE},
    {key: 'gateOut', name: ChargeConstant.Charge.GATE_OUT_RANGE},
    {key: 'specHandlingCodeList', name: ChargeConstant.Charge.SPECIAL_HANDLING_CODE},
    {key: 'specHandlingCodeD1List', name: ChargeConstant.Charge.SPECIAL_HANDLING_CODE_D1},
    {key: 'endVisitInd', name: ChargeConstant.Charge.END_VISIT_IND},
    {key: 'fromTml', name: ChargeConstant.Charge.FROM_TML},
    {key: 'toTml', name: ChargeConstant.Charge.TO_TML},
]

export const CriteriaDirectoryContainerCheckboxOptionList: CheckboxPropsList = {
    option: {key: 'container', name: ChargeConstant.Charge.CONTAINER},
    checkboxList: CriteriaDirectoryContainerCheckboxOption,
}

export const CriteriaDirectoryCheckboxOptionList: CheckboxPropsList[] = [
    CriteriaDirectoryVoyageCheckboxOptionList,
    CriteriaDirectoryChargeCheckboxOptionList,
    CriteriaDirectoryDocumentCheckboxOptionList,
    CriteriaDirectoryContainerCheckboxOptionList,
]